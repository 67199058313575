.console-game {
    display: flex;
    flex-direction: column;
    height: 100%;
    font-size: 8px;
  }

  .console-game, .console-output, .ping, .terminal-input-wrapper, .terminal-input, .server-logs, .devices, .terminal, .p, .h1, .h2, .h3 {
    font-size: 8px; 
}
  
  .console-output {
    flex-grow: 1; 
    overflow-y: auto; 
    height: 400px;
    padding: 10px;
    background-color: black;
    color: #00ff00;
    font-family: 'Courier New', Courier, monospace;
    box-sizing: border-box;
    font-size: 8px;
  }
  
  .terminal-input-wrapper {
    padding: 10px;
    background-color: #1a1a1a;
    border-top: 1px solid #00ff00;
    font-size: 8px;
  }


  .terminal-input {
    background-color: black;
    color: #00ff00;
    border: none;
    outline: none;
    padding: 10px;
    font-size: 8px;
    width: 100%; 
    box-sizing: border-box;
    font-family: 'Courier New', Courier, monospace;
    caret-color: #00ff00;
  }
  
  .terminal-input::placeholder {
    color: #008000;
    font-size: 8px;
  }
  

  .admin-terminal {
    background-color: black;
    color: #00ff00;
    border: none;
    outline: none;
    padding: 10px;
    font-size: 8px;
    width: 100%; 
    box-sizing: border-box;
    font-family: 'Courier New', Courier, monospace;
    caret-color: #00ff00;
  }