.faq-page {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  }
  
  .faq-item {
    margin-bottom: 15px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
  }
  
  .faq-question {
    cursor: pointer;
    padding: 10px;
    background-color: #e9e9e9;
    border-radius: 5px;
    transition: background-color 0.3s;
    text-align: center;
  }
  
  .faq-question:hover {
    background-color: #d1d1d1;
  }
  
  .faq-answer {
    margin-top: 10px;
    padding-left: 20px;
    text-align: justify;
  }
  
  /* Dark Mode for FAQ Page */

/* FAQ Page Container */
body.dark .faq-page {
  background-color: #2a2a2a;
  box-shadow: 0 2px 10px rgba(255, 255, 255, 0.1);
  color: #f0f0f0;
}

/* FAQ Items */
body.dark .faq-item {
  border-bottom: 1px solid #444;
}

/* FAQ Question */
body.dark .faq-question {
  background-color: #333333;
  color: #f0f0f0;
}

body.dark .faq-question:hover {
  background-color: #444444;
}

/* FAQ Answer */
body.dark .faq-answer {
  color: #cccccc;
}
