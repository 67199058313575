/* General Styles */
body {
    font-family: 'SUSE', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f4f4f4;
    color: #333;

}

h1, h2 {
    color: #333;
}

a {
    color: rgb(1, 1, 155);
    text-decoration: none;
}

a:hover {
    font-weight: bold;
}

.container {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
    background-color: #ffffff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.news-item {
    background-color: #fff;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.news-title {
    font-size: 1.2em; 
    color: #2c3e50;
    cursor: pointer;
    margin: 0;
    padding: 10px;
    border-radius: 5px;
    background-color: #f8f8f8;
    transition: background-color 0.3s ease;
}

.news-title:hover {
    background-color: #e0e0e0;
}

.news-item p {
    margin-top: 10px;
    line-height: 1.5;
}

.news-item .date {
    font-size: 0.9em;
    color: #999;
    margin-bottom: 10px;
}

@media screen and (max-width: 768px) {
    .container {
        padding: 10px;
    }

    .news-item {
        padding: 15px;
    }

    .news-title {
        font-size: 1em; 
    }

    .news-item p {
        font-size: 0.95em;
    }
}

/* General Styles for Dark Mode */
body.dark {
    background-color: #181818; 
    color: #f0f0f0; 
}

body.dark h1, body.dark h2 {
    color: #f0f0f0; 
}

body.dark a {
    color: #941212; 
}

body.dark a:hover {
    color: #add8e6; 
    font-weight: bold;
}

/* Container for Dark Mode */
body.dark .container {
    background-color: #2a2a2a; 
    box-shadow: 0 2px 10px rgba(255, 255, 255, 0.1); 
}

/* News Item for Dark Mode */
body.dark .news-item {
    background-color: #2b2b2b; 
    box-shadow: 0 2px 5px rgba(255, 255, 255, 0.1); 
}

body.dark .news-title {
    color: #e0e0e0; 
    background-color: #333333; 
}

body.dark .news-title:hover {
    background-color: #444444; 
}

/* Paragraphs in News Items */
body.dark .news-item p {
    color: #dcdcdc; 
}

body.dark .news-item .date {
    color: #b3b3b3; 
}

/* Media Queries for Dark Mode */
@media screen and (max-width: 768px) {
    body.dark .container {
        padding: 10px;
    }

    body.dark .news-item {
        padding: 15px;
    }

    body.dark .news-title {
        font-size: 1em;
    }

    body.dark .news-item p {
        font-size: 0.95em;
    }
}

