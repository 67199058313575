@import url('https://fonts.googleapis.com/css2?family=Handjet&display=swap');

.modal-close-top-right {
  position: fixed;
  top: 100px;
  right: 10px;
  background: transparent;
  border: none;
  color: #ff0000; 
  font-size: 24px;
  cursor: pointer;
  z-index: 1001; 
}

.modal-close-top-right:hover {
  color: #ff4d4d; 
  background:none;
  font-weight: bold;
}

/* General Font Styling for Modal */
.modal-content {
  margin-top: 60px;
  font-family: 'Handjet', sans-serif;
  color: #ffffff;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7); 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  max-height: 80vh;
  overflow-y: auto;
  padding: 20px;
  box-sizing: border-box;
  background: #1a1a1a;
  color: #ffffff;
  border-radius: 5px;
  width: 100%;
  max-width: 600px;
  position: relative;
}


.modal-glitch {
  animation: glitch 0.3s infinite alternate;
}

@keyframes glitch {
  0% { text-shadow: 2px 2px red, -2px -2px blue; }
  50% { text-shadow: -2px -2px red, 2px 2px blue; }
  100% { text-shadow: 2px 2px blue, -2px -2px red; }
}
