.journal-container {
    max-width: 850px;
    margin: 0 auto;
    padding: 20px;
    background-color: #1e1e1e;
    color: #f0f0f0;
    border-radius: 8px;
  }
  
  .journal-container h1 {
    font-size: 15px;
    text-align: center;
  }
  
  .journal-container h2 {
    font-size: 20px;
    margin: 20px;
  }
  
  .search-bar input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .previous-entries {
    max-height: 250px; 
    overflow-y: auto; 
    border: 1px solid #333;
    padding: 10px;
    border-radius: 8px;
    background-color: #2a2a2a;
  }
  
  .previous-entries ul {
    list-style: none; 
    padding: 0;
    margin: 0;
  }
  
  .previous-entries li {
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #444;
    border-radius: 6px;
    background-color: #333;
    font-size: 16px; 
  }
  
  .previous-entries h3 {
    margin: 0 0 5px;
    font-size: 15px; 
  }
  
  .previous-entries p {
    margin: 0;
    font-size: 12px; 
  }
  
  .previous-entries .redacted-date {
    font-family: 'Courier New', Courier, monospace;
    font-size: 9px;
    color: black;
    background-color: black;
    padding: 2px 5px;
    border-radius: 3px;
    display: inline-block;
    margin-top: 2px;
  }
  
  .previous-entries .delete-entry-button {
    margin: 10px auto; 
    width: 20%; 
    padding: 5px;
    font-size: 10px;
    background-color: #b22222; 
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
  }
  
  .previous-entries .delete-entry-button:hover {
    background-color: #a11414;
  }
  
  .previous-entries::-webkit-scrollbar {
    width: 12px;
  }
  
  .previous-entries::-webkit-scrollbar-thumb {
    background-color: #444;
    border-radius: 5px;
  }
  
  .previous-entries::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
  
  .add-entry {
    display: flex;
    flex-direction: column; 
    justify-content: center;
    align-items: center;
    margin-top: 20px; 
  }
  
  .add-entry input,
  .add-entry textarea {
    width: 80%; 
    max-width: 500px; 
    margin-bottom: 10px; 
    padding: 10px; 
    font-size: 12px; 
    border: 1px solid #ccc; 
    border-radius: 4px; 
  }
  
  .add-entry button {
    width: 30%; 
    max-width: 500px; 
    padding: 10px;
    font-size: 16px;
    background-color: #b22222; 
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
  }
  
  .add-entry button:hover {
    background-color: #a11a1a;
  }
  