.landing-container {
  text-align: center;
  margin-top: 50px;
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.menu {
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-top: 30px;
  flex-wrap: wrap; 
}

.menu-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  color: black;
  font-size: 20px;
  flex: 1; 
  max-width: 120px; 
}

.menu-item .icon {
  font-size: 50px;
}

.menu-item .label {
  margin-top: 10px;
}


body.dark .landing-container {
  background-color: #2a2a2a;
  color: #f0f0f0;
  box-shadow: 0 2px 10px rgba(255, 255, 255, 0.1);
}

body.dark .menu-item {
  color: #f0f0f0;
}

body.dark .menu-item:hover {
  color: #a11414;
}

body.dark .menu-item .icon {
  color: #ffffff;
}

body.dark .menu-item .label {
  color: #e0e0e0;
}


@media screen and (max-width: 768px) {
  .menu {
      gap: 20px;
  }

  .menu-item {
      font-size: 16px;
      max-width: 80px;
  }

  .menu-item .icon {
      font-size: 40px;
  }

  .menu-item .label {
      font-size: 14px;
  }
}

@media screen and (max-width: 480px) {
  .menu {
      gap: 10px;
  }

  .menu-item {
      font-size: 14px;
      max-width: 70px;
  }

  .menu-item .icon {
      font-size: 30px;
  }

  .menu-item .label {
      font-size: 12px;
  }
}
