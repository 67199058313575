/* General Styles */
.investors-page {
    font-family: Arial, sans-serif;
    color: #333;
    line-height: 1.6;
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
    background-color: #ffffff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    text-align: justify;
  }
  
  .investors-page h1,
  .investors-page h2,
  .investors-page h3 {
    color: #333;
  }
  
  .investors-page h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: #444;
  }
  
  .investors-page h2 {
    font-size: 1.8em;
    margin-bottom: 15px;
    color: #666;
  }
  
  .investors-page h3 {
    font-size: 1.5em;
    margin-bottom: 10px;
    color: #555;
  }
  
  .investors-page p {
    font-size: 1.1em;
    margin-bottom: 20px;
    color: #444;
    text-align: justify;

  }
  
  /* List Styles */
  .investors-page ul {
    list-style-type: disc;
    padding-left: 20px;
    margin-bottom: 20px;
    text-align: justify;

  }
  
  .investors-page ul li {
    margin-bottom: 10px;
  }
  
  /* Contact Link */
  .investors-page a {
    color: rgb(1, 1, 155);
    text-decoration: none;
    max-width: 1000px;

  }
  
  .investors-page a:hover {
    font-weight: bold;
  }
  
  /* Investor Profiles */
  .investor-profile {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    padding: 15px;
    background-color: #f1f1f1;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .investor-profile img {
    width: 120px;
    height: 120px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 20px;
  }
  
  .investor-profile div {
    max-width: 600px;
  }
  
  .investor-profile h3 {
    margin-bottom: 5px;
  }
  
  .investor-profile p {
    margin: 0;
    color: #666;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .investor-profile {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .investor-profile img {
      margin-bottom: 10px;
    }
  
    .investor-profile div {
      max-width: 100%;
    }
  }

  /* Dark Mode for Investors Page */

/* General Styles */
body.dark .investors-page {
  color: #f0f0f0;
  background-color: #2a2a2a;
  box-shadow: 0 2px 10px rgba(255, 255, 255, 0.1);
}

/* Headings */
body.dark .investors-page h1,
body.dark .investors-page h2,
body.dark .investors-page h3 {
  color: #f0f0f0;
}

body.dark .investors-page h1 {
  color: #dddddd;
}

body.dark .investors-page h2 {
  color: #cccccc;
}

body.dark .investors-page h3 {
  color: #bbbbbb;
}

/* Paragraphs */
body.dark .investors-page p {
  color: #d0d0d0;
}

/* List Styles */
body.dark .investors-page ul {
  color: #d0d0d0;
}

/* Contact Link */
body.dark .investors-page a {
  color: #f0f0f0;
}

body.dark .investors-page a:hover {
  font-weight: bold;
  color: #a11414;
}

/* Investor Profiles */
body.dark .investor-profile {
  background-color: #3a3a3a;
  box-shadow: 0 2px 4px rgba(255, 255, 255, 0.1);
}

body.dark .investor-profile p {
  color: #b0b0b0;
}

/* Responsive Design */
@media (max-width: 768px) {
  body.dark .investor-profile img {
      margin-bottom: 10px;
  }

  body.dark .investor-profile div {
      max-width: 100%;
  }
}
  