.careers-page {
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
    background-color: #ffffff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: #2c3e50;
    text-align: center;
}

p {
    font-size: 1.2em;
    line-height: 1.6;
    color: #555;
    text-align: justify;
}

h2 {
    font-size: 1.8em;
    margin-bottom: 10px;
    color: #333;
    cursor: pointer;
}

h2.position-title:hover {
    color: rgb(1, 1, 155);
    font-weight: bold;
}

.position-details {
    margin: 20px 0;
    padding: 20px;
    background-color: #e0e0e0;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

ul {
    list-style-type: disc;
    margin-left: 20px;
}

ul li {
    margin-bottom: 10px;
}

h3 {
    font-size: 1.6em;
    margin-top: 30px;
    color: #2c3e50;
    text-align: center;
}

a:link {
    color: rgb(1, 1, 155);
    text-decoration: none;

}

a:hover {
    font-weight: bold;}

    .apply-button-container {
        display: flex;
        justify-content: center; 
        margin-top: 20px; 
      }
      
      .apply-button {
        background-color: #4e73df; 
        color: white; 
        border: none;
        padding: 10px 20px;
        cursor: pointer;
        border-radius: 5px;
        font-size: 16px;
        font-weight: bold;
        text-decoration: none; 
      }
      
      .apply-button:hover {
        background-color: #3b5bb5; 
      }


/* Dark Mode for Careers Page */

/* Careers Page Container */
body.dark .careers-page {
    background-color: #2a2a2a;
    box-shadow: 0 2px 10px rgba(255, 255, 255, 0.1);
    color: #f0f0f0;
}

/* Headings */
body.dark h1, body.dark h2, body.dark h3 {
    color: #f0f0f0;
}

/* Position Title Hover */
body.dark h2.position-title:hover {
    color: #941212;
    font-weight: bold;
}

/* Position Details */
body.dark .position-details {
    background-color: #333333;
    box-shadow: 0 2px 5px rgba(255, 255, 255, 0.1);
}

/* Paragraphs */
body.dark p {
    color: #cccccc;
}

/* Lists */
body.dark ul li {
    color: #cccccc;
}

/* Links */
body.dark a:link {
    color: #f0f0f0;
}

body.dark a:hover {
    font-weight: bold;
    color: #f0f0f0;
}

/* Apply Button */
body.dark .apply-button {
    background-color: #941212;
    color: white;
}

body.dark .apply-button:hover {
    background-color: #a11414;
}

      