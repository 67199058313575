.quantum-harmonics {
    width: 100%;
    max-width: 1200px; 
    margin: 0 auto;
    text-align: center;
    font-family: monospace;
    color: #00ff00;
    background-color: black;
    padding: 10px;
    font-size: 8px;
  }
  
  .slider-control {
    margin: 5px 0;
  }
  
  .slider-control label {
    display: block;
    margin-bottom: 5px;
    font-size: 8px;
    color: #00ff00;
  }
  
  input[type=range] {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 4px;
    border-radius: 5px;
    outline: none;
    background: #444;
    opacity: 0.8;
    transition: background 0.3s ease;
  }
  
  input[type=range]:hover {
    opacity: 1;
  }
  
  input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 18px;
    height: 25px;
    background: #000000;
    cursor: pointer;
    border-radius: 10%;
  }
  
  input[type=range]::-moz-range-thumb {
    width: 12px;
    height: 12px;
    background: #00ff00;
    cursor: pointer;
    border-radius: 50%;
  }
  
  .stable {
    background: #4caf50; 
  }
  
  .close {
    background: #ffeb3b; 
  }
  
  .unstable {
    background: #f44336; 
  }
  
  .status {
    font-size: 8px;
    padding-top: 5px;
  }
  
  .status.success {
    color: #4caf50;
  }
  
  .status.error {
    color: #f44336; 
  }


  .harmonic-success {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.success-message {
  font-size: 10px;
  color: limegreen;
  animation: blink 1s infinite;
}

@keyframes blink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0.5; }
}


@keyframes pulse {
  0% {
    background-color: #0096FF; 
  }
  50% {
    background-color: #bbe3ff; 
  }
  100% {
    background-color: #0096FF; 
  }
}

.pulsing-slider {
  animation: pulse 2.5s infinite; 
}

.slider-control input[type='range'] {
  width: 100%;
  appearance: none;
  height: 8px;
  background: #ccc;
  outline: none;
  transition: background 0.2s ease;
}



  @media (max-width: 768px) {
    .quantum-harmonics {
      max-width: 80%; 
      margin: 0 auto;
    }
  
    input[type="range"] {
      height: 8px; 
    }
  
    input[type="range"]::-webkit-slider-thumb {
      width: 20px; 
      height: 25px; 
    }
  
    label {
      font-size: 8px; 
    }
  
    h3 {
      font-size: 8px; 
    }
  
    .status {
      font-size: 8px; 
    }
  }
  

  @media (max-width: 480px) {
    .quantum-harmonics {
      max-width: 80%; 
    }
  
    input[type="range"] {
      height: 6px; 
    }
  
    input[type="range"]::-webkit-slider-thumb {
      width: 20px; 
      height: 25px;
    }
  
    label {
      font-size: 8px; 
    }
  
    h3 {
      font-size: 8px; 
    }
  
    .status {
      font-size: 8px; 
    }
  }
  