/* General Header Styles */
.header {
    position: fixed;
    top: 0;
    left: 250px; 
    width: calc(100% - 250px);  
    height: 150px; 
    background-color: #333; 
    z-index: 999;  
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden; 
  }
  
  .header-image {
    width: 100%;
    height: 100%;
    object-fit: cover; 
    object-position: 0 -175px;
  }
  
  

  @media screen and (max-width: 768px) {
    .header {
      padding-bottom: 0;
      margin-bottom: 0;
      left: 0; 
      width: 100%;
      height: 100px; 
    }
    .header-image {
        object-position: 0 -20px;
    }
  }
  