.contact-page {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
    font-family: 'SUSE', sans-serif;
  }
  
  .contact-page h1 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
    font-size: 2em;
  }
  
  .contact-page p {
    color: #666;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  .contact-page h2 {
    color: #333;
    margin-top: 30px;
    font-size: 1.5em;
    border-bottom: 2px solid #ddd;
    padding-bottom: 5px;
  }
  
  .contact-page a {
    color: rgb(1, 1, 155);
    text-decoration: none;
  }
  
  .contact-page a:hover {
    font-weight: bold;
  }
  
  .contact-personnel {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 20px;
  }
  
  .person {
    width: 45%;
    margin-bottom: 20px;
    background-color: #fff;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .person img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin-bottom: 15px;
  }
  
  .person p {
    color: #555;
    margin: 0;
  }
  
  .person strong {
    display: block;
    color: #333;
    margin-bottom: 5px;
    font-size: 1.2em;
  }
  
  /* Dark Mode for Contact Page */

/* Contact Page Container */
body.dark .contact-page {
  background-color: #2a2a2a;
  box-shadow: 0 2px 10px rgba(255, 255, 255, 0.1);
  color: #f0f0f0;
}

/* Headings */
body.dark .contact-page h1, 
body.dark .contact-page h2 {
  color: #f0f0f0;
  border-bottom: 2px solid #555;
}

/* Paragraphs */
body.dark .contact-page p {
  color: #cccccc;
}

/* Links */
body.dark .contact-page a {
  color: #941212;
}

body.dark .contact-page a:hover {
  font-weight: bold;
  color: #941212;
}

/* Contact Personnel Section */
body.dark .contact-personnel {
  justify-content: space-around;
}

/* Person Card */
body.dark .person {
  background-color: #333333;
  box-shadow: 0 2px 4px rgba(255, 255, 255, 0.1);
}

/* Person's Text */
body.dark .person p {
  color: #dcdcdc;
}

body.dark .person strong {
  color: #ffffff;
}

/* Person's Image */
body.dark .person img {
  filter: brightness(0.8);
}
