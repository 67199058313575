/* General Styles */
.legal-page {
    font-family: Arial, sans-serif;
    color: #333;
    line-height: 1.6;
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
    background-color: #ffffff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  .legal-page h2,
  .legal-page h3 {
    color: #444;
  }
  
  .legal-page h2 {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: #222;
  }
  
  .legal-page h3 {
    font-size: 1.8em;
    margin-bottom: 15px;
    color: #333;
  }
  
  .legal-page p {
    font-size: 1.1em;
    margin-bottom: 20px;
    color: #444;
    text-align: justify;
  }
  
  /* Styled Headers */
  .legal-page h2 {
    border-bottom: 2px solid #999;
    padding-bottom: 10px;
    margin-bottom: 30px;
  }
  
  .legal-page h3 {
    background-color: #ddd;
    padding: 10px;
    border-left: 5px solid #888;
    margin-bottom: 20px;
  }
  
  .legal-page p {
    line-height: 1.8;
    margin-bottom: 25px;
  }
  
  .legal-page strong {
    color: #000;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .legal-page {
      padding: 15px;
    }
  
    .legal-page h2 {
      font-size: 2em;
    }
  
    .legal-page h3 {
      font-size: 1.5em;
    }
  
    .legal-page p {
      font-size: 1em;
    }
  }
  

  /* Dark Mode for Legal Page */

/* General Styles */
body.dark .legal-page {
  color: #f0f0f0;
  background-color: #2a2a2a;
  box-shadow: 0 2px 10px rgba(255, 255, 255, 0.1);
}

/* Headers */
body.dark .legal-page h2,
body.dark .legal-page h3 {
  color: #f0f0f0;
}

body.dark .legal-page h1:hover, 
.legal-page h2:hover,
.legal-page h3:hover  {
  color: #941212;
  text-decoration: none;}

body.dark .legal-page h2 {
  color: #f0f0f0;
  border-bottom: 2px solid #555;
}

body.dark .legal-page h3 {
  background-color: #444;
  border-left: 5px solid #666;
}

/* Paragraphs and Text */
body.dark .legal-page p {
  color: #d0d0d0;
}

body.dark .legal-page strong {
  color: #ffffff;
}

/* Responsive Design */
@media (max-width: 768px) {
  body.dark .legal-page h2 {
      font-size: 2em;
  }

  body.dark .legal-page h3 {
      font-size: 1.5em;
  }

  body.dark .legal-page p {
      font-size: 1em;
  }
}
