.about-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  }
  
  .about-intro, .about-mission-vision, .about-history, .about-values, .about-team, .about-contact {
    margin-bottom: 40px;
  }
  
  .about-intro h1 {
    font-size: 3em;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .about-intro p {
    text-align: center;
    font-size: 1.2em;
    line-height: 1.5;
    margin-top: 20px;
    padding: 15px;
    background-color: #f1f1f1;
    border: 1px solid #ddd;
    border-radius: 8px;
  }
  
  .about-mission-vision h2, .about-history h2, .about-jeff h2 {
    font-size: 2em;
    margin-bottom: 15px;
    cursor: pointer;
    color: #333;
    transition: color 0.3s;
  }
  
  .about-mission-vision h2:hover, .about-history h2:hover, .about-jeff h2:hover {
    color: #007BFF;
    text-decoration: none;
  }
  
  .about-mission-vision p, .about-history p, .about-jeff p {
    font-size: 1.1em;
    line-height: 1.6;
    color: #555;
    margin-top: 20px;
    padding: 15px;
    background-color: #f1f1f1;
    border: 1px solid #ddd;
    border-radius: 8px;
  }

  .hidden-icon {
    font-size: 20px;
    color: rgba(0, 0, 0, 0.05);
    cursor: pointer;
    margin: 20px 0;
    text-align: center;
    transition: color 0.3s;
  }
  
  .hidden-icon:hover {
    color: rgba(0, 0, 0, 0.5); 
    text-decoration: none;
  }
  
  .about-jeff {
    margin-top: 20px;
    padding: 15px;

  }
  
  .about-jeff.visible {
    display: block;
  }

  /* Responsive Styling */
  @media (max-width: 768px) {
    .about-container {
      padding: 20px;
    }
  
    .about-intro h1 {
      font-size: 2em;
    }
  
    .about-intro p {
      font-size: 1em;
    }
  
    .about-mission-vision h2,
    .about-history h2,
    .about-jeff h2,
    .about-values h2,
    .about-team h2,
    .about-contact h2 {
      font-size: 1.5em;
    }
  
    .about-mission-vision p,
    .about-history p,
    .about-jeff p,
    .about-values li,
    .about-team h3,
    .about-team p,
    .about-contact p {
      font-size: 1em;
    }
  }


/* Dark Mode for About Page */

/* About Page Container */
body.dark .about-container {
  background-color: #2a2a2a;
  box-shadow: 0 2px 10px rgba(255, 255, 255, 0.1);
  color: #941212;
}

/* Section Headings */
body.dark .about-container h1,
body.dark .about-container h2,
body.dark .about-intro h1,
body.dark .about-intro h2,
body.dark .about-intro h3,
body.dark .about-mission-vision h2,
body.dark .about-history h2,
body.dark .about-jeff h2 {
  color: #f0f0f0;
}

body.dark .about-mission-vision h2:hover,
body.dark .about-intro h2:hover,
body.dark .about-history h2:hover,
body.dark .about-jeff h2:hover {
  color: #941212;
  text-decoration: none;
}

/* Paragraphs */
body.dark .about-intro p,
body.dark .about-mission-vision p,
body.dark .about-history p,
body.dark .about-jeff p {
  background-color: #333333;
  color: #cccccc;
  border: 1px solid #444;
}

/* Hidden Icon */
body.dark .hidden-icon {
  color: rgba(255, 255, 255, 0.05);
}

body.dark .hidden-icon:hover {
  color: rgba(255, 255, 255, 0.1);
  text-decoration: none;
}

/* Responsive Styling */
@media (max-width: 768px) {
  body.dark .about-intro h1 {
    font-size: 2em;
  }

  body.dark .about-intro p,
  body.dark .about-mission-vision p,
  body.dark .about-history p,
  body.dark .about-jeff p {
    font-size: 1em;
  }

  body.dark .about-mission-vision h2,
  body.dark .about-history h2,
  body.dark .about-jeff h2 {
    font-size: 1.5em;
  }
}

