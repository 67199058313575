.terminal-modal {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 420px; 
    background-color: black;
    color: #00ff00;
    border-top: 2px solid #00ff00;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 0;
  }
  
  .terminal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #1a1a1a;
    border-bottom: 1px solid #00ff00;
    color: #00ff00;
  }
  
  .terminal-header h4 {
    font-size: 8px; 
    margin: 0;
  }
  
  .terminal-close-btn {
    background: transparent;
    width: 20px;
    height: 20px;
    font-size: 14px;
    cursor: pointer;
    position: absolute;
    top: 5px;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    border-radius: 3px;
    top: 0%; 
    transform: translateY(0%);
  }
  
  .terminal-close-btn:hover {
    background-color: #ff0000;
    color: white;
  }
  
  .terminal-content {
    flex-grow: 1;
    overflow-y: auto; 
    padding: 10px;
    font-size: 10px;
    font-family: 'Courier New', Courier, monospace;
    width: 100%;
    box-sizing: border-box;
  }

  .console-output p {
    margin: 0; 
    line-height: 1.2; 
  }
  
  
  .terminal-input-wrapper {
    display: flex;
    padding: 10px;
    background-color: #1a1a1a;
    border-top: 1px solid #00ff00;
    box-sizing: border-box;
  }
  
  .terminal-input {
    background-color: black;
    color: #00ff00;
    border: none;
    outline: none;
    padding: 10px;
    font-size: 10px;
    width: 100%; 
    box-sizing: border-box;
    font-family: 'Courier New', Courier, monospace;
    caret-color: #00ff00;
  }
  
  .terminal-input::placeholder {
    color: #008000;
  }


  @media only screen and (max-width: 768px) {
    .touch-area {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 10%; 
      z-index: 1;
      opacity: 0; 
      background-color: transparent; 
    }
  }
  
  @media only screen and (min-width: 769px) {
    .touch-area {
      display: none;
    }
  }
  