/* General Styles */
body {
    font-family: 'SUSE', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f4f4f4;
    color: #333;
    
}

.home-container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
    background-color: #ffffff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    text-align: justify;
}

h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: #2c3e50;
}

p {
    font-size: 1.2em;
    line-height: 1.6;
    margin-bottom: 40px;
    color: #555;
}

.features {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.feature {
    flex: 1;
    margin: 15px;
    padding: 20px;
    background-color: #e0e0e0;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.feature h2 {
    font-size: 1.8em;
    margin-bottom: 10px;
    color: #333;
}

.feature p {
    font-size: 1em;
    line-height: 1.5;
    color: #555;
}

.logo {
    display: block;
    margin: 0 auto; 
    max-width: 120px; 
  }

@media screen and (max-width: 768px) {
    .features {
        flex-direction: column;
    }

    .feature {
        margin: 10px 0;
    }

    h1 {
        font-size: 2em;
    }

    p {
        font-size: 1em;
    }
}

/* Dark Mode for Home Page */

/* General Styles */
body.dark {
    background-color: #181818;
    color: #f0f0f0;
}

body.dark .home-container {
    background-color: #2a2a2a;
    box-shadow: 0 2px 10px rgba(255, 255, 255, 0.1);
}

/* Headings */
body.dark h1 {
    color: #f0f0f0;
}

/* Paragraphs */
body.dark p {
    color: #cccccc;
}

/* Features Section */
body.dark .features {
    justify-content: space-between;
    flex-wrap: wrap;
}

body.dark .feature {
    background-color: #333333;
    box-shadow: 0 2px 5px rgba(255, 255, 255, 0.1);
}

body.dark .feature h2 {
    color: #dddddd;
}

body.dark .feature p {
    color: #cccccc;
}

/* Logo */
body.dark .logo {
    filter: brightness(0.8);
}

/* Responsive Design */
@media screen and (max-width: 768px) {
    body.dark .features {
        flex-direction: column;
    }

    body.dark h1 {
        font-size: 2em;
    }

    body.dark p {
        font-size: 1em;
    }
}
