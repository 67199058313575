.App {
  display: flex;
  min-height: 100vh;
  background-color: #f4f4f4;
  overflow: hidden;
}

body {
  font-family: 'SUSE', sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
  font-weight: normal;
}

a:hover {
  font-weight: bold;
}

html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
  line-height: 1;
}


.sidebar {
  width: 250px;
  background: linear-gradient(135deg, #4e73df, #1cc88a);
  color: white;
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
  height: 95vh; 
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  transition: none;
  overflow-y: auto; 
}

@media screen and (max-width: 768px) {
  .sidebar {
    padding-top: 50px; 
    overflow-y: auto; 
  }
}

.sidebar::-webkit-scrollbar {
  width: 8px; 
}

.sidebar::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.4); 
  border-radius: 4px;
}

.sidebar::-webkit-scrollbar-thumb:hover {
  background-color: rgba(255, 255, 255, 0.6);
}

.main-content {
  flex-grow: 1;
  padding: 20px;
  margin-left: 270px;
  background: linear-gradient(135deg, #ffffff, #d4d4d4);
  margin-top: 80px;
  border-left: 2px solid #939393;
  overflow: auto;
}

h2 {
  text-align: center;
  font-size: 2em;
  color: #333;
  margin-top: 0;
  padding-top: 0;
}

.container {
  width: 100%;
  max-width: 300px;
  padding: 20px;
  box-shadow: none;
  background-color: transparent;
}

h1, h2 {
  color: #333;
}

nav {
  margin-top: 20px;
  color: white;
}

nav a {
  display: block;
  color: white !important;
  padding: 10px;
  text-decoration: none;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

nav a:hover {
  background-color: rgba(255, 255, 255, 0.2);
  text-decoration: none;
}

input, button {
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  border-radius: 5px;
  border: 1px solid #ccc;
}

button {
  background-color: #5c67f2;
  color: white;
  border: none;
  }

button:hover {
  background-color: #5058cc;
  cursor: pointer;
  text-decoration: none;
  transform: translateY(-2px);
}

button:active {
  background-color: #5d66e9;
  transform: translateY(0);
}

.error {
  color: red;
  font-size: 14px;
}

.nav-container {
  margin-bottom: 20px;
}

.login-container {
  margin-top: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  padding-top: 20px;
}

.login-container h1 {
  font-size: 16px;
  color: white;
  margin-bottom: 10px;
}

.logout-link {
  color: white;
  text-decoration: none;
}

.logout-link:hover {
  font-weight: bold;
  text-decoration: none;
}

.logout-link:visited {
  color: white;
}

.login-container p {
  margin: 0 0 10px;
  font-weight: bold;
}

a:link {
  color: white;
}

a:visited {
  color: white;
}

.sidebar input, .sidebar button {
  width: calc(100% - 50px);
  padding: 10px;
  margin: 5px 0;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.experiment-log a {
  color: rgb(1, 1, 155);
  text-decoration: none;
}

.experiment-log {
  background-color: #1b1b1b;
  color: #00ff00;
  font-family: 'Courier New', Courier, monospace;
  padding: 20px;
  border-radius: 8px;
}

.experiment-log-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  color: #39ff14;
}

.experiment-log-table th, .experiment-log-table td {
  padding: 12px;
  border: 1px solid #39ff14;
  text-align: left;
  font-family: 'Courier New', monospace;
}

.experiment-log-table th {
  background-color: #222;
  color: #39ff14;
  font-weight: bold;
}

.experiment-log-table tr:nth-child(even) {
  background-color: #333;
}

.experiment-log-table tr:hover {
  background-color: #444;
  text-decoration: none;
}

@media screen and (max-width: 768px) {
  .table-wrapper {
    overflow-x: auto;
  }

  .experiment-log-table th, .experiment-log-table td {
    padding: 10px;
    font-size: 14px;
  }

  .experiment-log-table {
    width: 800px;
  }

  .table-wrapper {
    -webkit-overflow-scrolling: touch;
  }
}

.email-container {
  display: flex;
  height: 100vh;
}

.email-container a {
  color: #4e73df;
  text-decoration: none;
}

.email-container a:hover {
  font-weight: bold;
  text-decoration: none;
}


.email-list {
  width: 35%;
  max-width: 300px;
  background-color: #f1f1f1;
  border-right: 1px solid #ccc;
  overflow-y: auto;
}

.email-list-item {
  padding: 15px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.email-list-item:hover {
  background-color: #e0e0e0;
  text-decoration: none;
}

.email-preview {
  flex-grow: 1;
  padding: 20px;
  background-color: #fff;
  overflow-y: auto;
  display: none;
}

.email-preview.open {
  display: block;
}

.email-preview-header {
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  margin-bottom: 15px;
}

.email-preview-content {
  margin: 0;
  line-height: 1.4 !important;
  white-space: pre-wrap;
  font-size: 16px;
  color: #444;
}

.email-preview-content p {
  margin: 0;
  line-height: 1.4 !important;
  white-space: pre-wrap;
  font-size: 16px;
  color: #444;
}

.email-header-subject {
  font-size: 20px;
  font-weight: bold;
  color: #000;
}

.email-back-button {
  display: block;
  padding: 5px;
  background-color: #4e73df;
  color: white;
  text-align: center;
  font-size: 12px;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 60px;
  z-index: 60;
  position: relative;
}

@media screen and (max-width: 768px) {
  .email-container {
    flex-direction: column;
  }

  .email-list {
    width: 100%;
  }

  .email-preview {
    display: none;
  }

  .email-preview.open {
    display: block;
  }

  .email-back-button {
    display: block;
    padding: 5px;
    background-color: #4e73df;
    color: white;
    text-align: center;
    font-size: 12px;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 60px;
    z-index: 60;
    position: relative;
  }

  .email-back-button:hover {
    background-color: #3751a3;
    text-decoration: none;
  }

  .sidebar-toggle {
    top: 10px;
    z-index: 1001;
  }

  .email-container {
    padding-top: 80px;
  }
}

@media screen and (max-width: 768px) {
  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    transform: translateY(-100%);
    transition: transform 0.3s ease-in-out;
  }

  .sidebar.shrink {
    transform: translateY(0);
  }

  .main-content {
    margin-left: 0;
    padding-top: 20px;
  }
}

.menu-bar {
  background-color: black;
  display: flex;
  color: white;
  text-align: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  padding: 5px;
  margin-top: 100px;
  font-size: 8px;
  font-weight: bold;
}

.menu-bar p {
  margin: 0;
  padding: 0;
}

@media (min-width: 769px) {
  .toggle-sidebar-btn {
    display: none; 
  }
}

.files-table a {
  color: #1a73e8;
  text-decoration: none;
}

.files-table a:hover {
  text-decoration: none;
}

.files a {
  color: rgb(1, 1, 155);
  text-decoration: none;
}

.files-page {
  padding: 20px;
}

.files-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.files-table th, .files-table td {
  padding: 12px;
  border: 1px solid #ddd;
  text-align: left;
}

.files-table th {
  background-color: #f4f4f4;
  color: #333;
  font-weight: bold;
}

.files-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.files-table tr:hover {
  background-color: #f1f1f1;
  text-decoration: none;
}

.file-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  vertical-align: middle;
}

@media screen and (max-width: 768px) {
  .files-table, .files-table thead, .files-table tbody, .files-table th, .files-table td, .files-table tr {
    display: block;
  }

  .files-table thead {
    display: none;
  }

  .files-table tr {
    margin-bottom: 15px;
    border-bottom: 2px solid #ddd;
  }

  .files-table td {
    text-align: right;
    padding-left: 50%;
    position: relative;
  }

  .files-table td:before {
    content: attr(data-label);
    position: absolute;
    left: 15px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    text-align: left;
    font-weight: bold;
    color: #333;
  }

  .file-icon {
    width: 40px;
    height: 40px;
    display: inline-block;
    margin-right: 5px;
  }

  .files-table td a {
    display: inline-block;
    margin: 5px 0;
  }
}

@media screen and (max-width: 768px) {
  .App {
    flex-direction: column;
  }

  .sidebar {
    padding-top: 50px;
    width: 100%;
    height: auto;
    position: fixed;
    top: 0;
    left: 0;
    transform: translateY(-100%);
    z-index: 1000;
  }

  .sidebar.shrink {
    transform: translateY(-100%);
  }

  .sidebar:not(.shrink) {
    transform: translateY(0);
  }

  .main-content {
    margin-top: 100px;
    order: 2;
    padding-top: 60px;
    margin-left: 0;
  }

  .sidebar nav {
    display: flex;
    flex-direction: column;
    overflow-x: auto;
  }

  .sidebar a {
    padding: 10px;
    margin-bottom: 10px;
    font-size: 14px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .sidebar-toggle {
    display: block;
    position: fixed;
    left: 10px;
    top: 5px;
    z-index: 1001;
    background: linear-gradient(135deg, #4e73df, #1cc88a);
    color: white;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 16px;
  }

  h2 {
    font-size: 1.5em;
  }
}

.files img {
  width: 100px;
  height: auto;
  margin: 10px;
}

.files ul {
  list-style-type: none;
  text-decoration: none;
}


.cctv-page {
  display: flex;
  flex-direction: column;  
  align-items: center;     
  justify-content: center; 
  height: 100vh;           
}

.header {
  text-align: center;
  margin-bottom: 20px;  
}

.dashboard-button {
  margin-bottom: 10px;
  padding: 10px 20px;
  background-color: #5a67d8;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.cctv-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);  
  grid-template-rows: repeat(3, 1fr);     
  gap: 10px; 
}

.cctv-grid img {
  width: 200px;
  height: 200px;
  object-fit: cover;
  position: relative; 
  transition: transform 0.3s ease, opacity 0.3s ease; 
}

.cctv-grid img:hover {
  transform: scale(1.05); 
  opacity: 0.8; 
  cursor: pointer;
  text-decoration: none;
}

.cctv-grid img::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0); 
  transition: background-color 0.3s ease; 
}

.cctv-grid img:hover::after {
  background-color: rgba(0, 0, 0, 0.2); 
  text-decoration: none;
}

@media screen and (max-width: 768px) {
  .cctv-grid {
    grid-template-columns: repeat(2, 1fr); 
  }
}

@media screen and (max-width: 480px) {
  .cctv-grid {
    grid-template-columns: 1fr; 
  }
}

body.dark {
  background-color: #181818;
  color: #f0f0f0;
}

body.dark .App {
  background-color: #1e1e1e;
}

body.dark a {
  color: #941212;
}

body.dark a:hover {
  font-weight: bold;
  color: #a11414;
  text-decoration: none;
}

body.dark .sidebar {
  background: linear-gradient(135deg, #2b2b2b, #1c1c1c);
  color: #f0f0f0;
}

body.dark .sidebar a {
  color: #f0f0f0 !important;
}

body.dark .sidebar a:hover {
  background-color: rgba(255, 255, 255, 0.1);
  text-decoration: none;
}

body.dark button {
  background-color: #941212;
  color: white;
  box-shadow: 0 5px 15px rgba(157, 3, 3, 0.4);
  outline: none;
}

body.dark button:active {
  background-color: #bd1616;
  box-shadow: none; 
  transform: translateY(0); 
}

body.dark button:hover {
  background-color: #a11414;
  cursor: pointer;
  text-decoration: none;
  transform: translateY(-2px);
}

body.dark .theme-toggle button {
  min-width: 120px;
  padding: 6px 10px;
  border-radius: 6px; 
  font-size: 10px;
}

.theme-toggle button {
  min-width: 120px;
  padding: 6px 10px;
  border-radius: 6px; 
  font-size: 10px;
}

body.dark .theme-toggle button:active {
  background-color: #bd1616;
  box-shadow: none; 
  transform: translateY(0); 
}

body.dark .main-content {
  background: linear-gradient(135deg, #2a2a2a, #1e1e1e);
  color: #f0f0f0;
}

body.dark h1, body.dark h2 {
  color: #f0f0f0;
}

body.dark .container {
  background-color: #2a2a2a;
  color: #f0f0f0;
}

body.dark .home-container {
  background-color: #141414;
  color: #f0f0f0;
}

{
  background-color: #141414;
  color: #f0f0f0;
}

body.dark p {
  color: #f0f0f0;
}

body.dark .login-container p {
  color: #f0f0f0;
}

body.dark .experiment-log {
  background-color: #1b1b1b;
  color: #00ff00;
}

body.dark .experiment-log-table th, 
body.dark .experiment-log-table td {
  border-color: #00ff00;
}

body.dark .experiment-log-table tr:nth-child(even) {
  background-color: #2a2a2a;
}

body.dark .experiment-log-table tr:hover {
  background-color: #333333;
  text-decoration: none;
}

body.dark .email-list {
  background-color: #2b2b2b;
  color: #f0f0f0;
}

body.dark .email-list-item {
  background-color: #2a2a2a;
  color: #f0f0f0;
}

body.dark .email-list-item:hover {
  background-color: #333333;
  text-decoration: none;
}

body.dark .email-preview {
  background-color: #1e1e1e;
  color: #f0f0f0;
}

body.dark .email-header-subject {
  color: #f0f0f0;
}

body.dark .email-back-button {
  background: #941212;
  color: #f0f0f0
}

@media screen and (max-width: 768px) {
  body.dark .sidebar {
    background-color: #2a2a2a;
  }
}


body.dark .file-management-page {
  background-color: #2a2a2a;
  color: #f0f0f0;
}

body.dark .file-management-page h1 {
  color: #ffffff;
}

body.dark table {
  width: 100%;
  border-collapse: collapse;
  background-color: #333;
}

body.dark th {
  background-color: #444;
  color: #ffffff;
  text-align: left;
  padding: 12px;
  font-weight: bold;
}

body.dark td {
  padding: 12px;
  border-bottom: 1px solid #555;
}

body.dark tr:nth-child(odd) {
  background-color: #3a3a3a;
}

body.dark tr:nth-child(even) {
  background-color: #2e2e2e;
}

body.dark tr:hover {
  background-color: #505050;
  text-decoration: none;
}

body.dark .actions .files-page .files-table a {
  color: #941212;
  text-decoration: none;
}

body.dark .actions .files-page .files-table a:hover {
  color: #a11414;
  text-decoration: none;
}

body.dark .back-button {
  background-color: #444;
  color: #f0f0f0;
  padding: 10px 20px;
  text-align: center;
  border-radius: 5px;
  text-decoration: none;
  display: inline-block;
  margin-bottom: 20px;
  transform: translateY();
}

body.dark .back-button:hover {
  background-color: #555;
  text-decoration: none;
  transform: translateY(-2px);
}


